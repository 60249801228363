@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

html,
body,
#root {
  margin: 0;
  height: 100vh;
  height: 100dvh;
  width: 100vw;
  width: 100dvw;
}

body {
  margin: 0;
  font-family: 'Baloo 2', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    -apple-system, BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Arvo', 'Times New Roman', serif;
}

.flashy-text {
  --bg-size: 400%;
  --color-one: rgb(3, 157, 76);
  --color-two: rgb(44, 219, 126);
  background: linear-gradient(90deg,
      var(--color-one),
      var(--color-two),
      var(--color-one)) 0 0 / var(--bg-size) 100%;
  color: transparent;
  background-clip: text;
}

@media (prefers-reduced-motion: no-preference) {
  .flashy-text {
    animation: animage-text-bg 8s linear infinite;
  }

  @keyframes animage-text-bg {
    to {
      background-position: var(--bg-size) 0;
    }
  }
}