table#plantEditor {
    white-space: nowrap;
    margin: 0;
    border: none;
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: fixed;
}

table#plantEditor tr {
    border-bottom: 1px solid #efefef;
    background-color: white;
}

table#plantEditor tbody tr:last-of-type {
    border: none;
}

table#plantEditor td,
table#plantEditor th {
    padding: 0.5rem 1rem;
}

table#plantEditor thead th {
    padding: 5px;
    position: sticky;
    top: 0;
    z-index: 1;
    width: 15vw;
    min-width: 130px;
    background: rgb(250, 250, 250);
}

table#plantEditor td {
    background: #fff;
    padding: 4px 5px;
    text-align: center;
}

table#plantEditor tbody th {
    text-align: center;
    position: relative;
}

table#plantEditor thead th:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
}

table#plantEditor tbody th {
    position: sticky;
    left: 0;
    background: rgb(250, 250, 250);
    z-index: 1;
}
