@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  25% {
    transform: scale3d(1.15, 1.15, 1.15);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes pulse2 {
  from {
    transform: scale3d(1, 1, 1);
  }

  25% {
    transform: scale3d(1.15, 1.15, 1.15);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.pulse-1 {
  animation-name: pulse;
  animation-duration: 0.4s;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
}

.pulse-2 {
  animation-name: pulse2;
  animation-duration: 0.4s;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
}

@keyframes shrink {
  from {
    transform: scale3d(1, 1, 1);
  }

  25% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes shrink2 {
  from {
    transform: scale3d(1, 1, 1);
  }

  25% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.shrink-1 {
  animation-name: shrink;
  animation-duration: 0.4s;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
}

.shrink-2 {
  animation-name: shrink2;
  animation-duration: 0.4s;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
}
